import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const VAPID_KEY=process.env.REACT_APP_VAPID_KEY;
const API_KEY=process.env.REACT_APP_API_KEY;
const AUTH_DOMAIN=process.env.REACT_APP_AUTH_DOMAIN;
const PROJECT_ID=process.env.REACT_APP_PROJECT_ID;
const STORAGE_BUCKET=process.env.REACT_APP_STORAGE_BUCKET;
const MESSAGING_SENDER_ID=process.env.REACT_APP_MESSAGING_SENDER_ID;
const APP_ID=process.env.REACT_APP_APP_ID;
const MEASUREMENT_ID=process.env.REACT_APP_MEASUREMENT_ID;


const config = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGING_SENDER_ID,
    appId: APP_ID,
    measurementId: MEASUREMENT_ID
};

const firebase = initializeApp(config);

const messaging = getMessaging(firebase);

export const getFcmToken = (setTokenFound) => {
    getToken(messaging, { vapidKey: VAPID_KEY })
    .then((currentToken) => {
        if (currentToken) {
            localStorage.setItem("fcm_token", currentToken);
            setTokenFound(true);
        } else {
            setTokenFound(false);
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        resolve(payload);
    });
  });
