import * as actions from './actions';

const initState = {
    users: [],
    error: null,
    isLoading: false,
    postSuccess: false,
    deleteSuccess: false,
    user: null
};

const getUsersStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getUsersSuccess = (state, action) => {
    return {
        ...state,
        users: action.data,
        error: null,
        isLoading: false
    }
}

const getUsersFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createUserStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        postSuccess: false
    }
}

const createUserSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true,
        //users: [...state.users, action.data.data]
    }
}

const createUserFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        postSuccess: false
    }
}

const getUserStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getUserSuccess = (state, action) => {
    return {
        ...state,
        user: action.data,
        error: null,
        isLoading: false
    }
}

const getUserFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const updateUserStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        postSuccess: false
    }
}

const updateUserSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true
    }
}

const updateUserFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        postSuccess: false
    }
}

const deleteUserStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        deleteSuccess: false
    }
}

const deleteUserSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        deleteSuccess: true,
        users: state.users.filter(user => user.id !== action.data)
    }
}

const deleteUserFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        deleteSuccess: false
    }
}

const getUserClean = (state, action) => {
    return {
        ...state,
        user: null,
        postSuccess: false,
        error: null
    }
}

const userListReducer = (state = initState, action) => {
    switch (action.type) {

        case actions.GET_USERS_START:
            return getUsersStart(state, action);
        case actions.GET_USERS_SUCCESS:
            return getUsersSuccess(state, action);
        case actions.GET_USERS_FAIL:
            return getUsersFail(state, action);

        case actions.CREATE_USER_START:
            return createUserStart(state, action);
        case actions.CREATE_USER_SUCCESS:
            return createUserSuccess(state, action);
        case actions.CREATE_USER_FAIL:
            return createUserFail(state, action);

        case actions.GET_USER_START:
            return getUserStart(state, action);
        case actions.GET_USER_SUCCESS:
            return getUserSuccess(state, action);
        case actions.GET_USER_FAIL:
            return getUserFail(state, action);

        case actions.UPDATE_USER_START:
            return updateUserStart(state, action);
        case actions.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action);
        case actions.UPDATE_USER_FAIL:
            return updateUserFail(state, action);

        case actions.DELETE_USER_START:
            return deleteUserStart(state, action);
        case actions.DELETE_USER_SUCCESS:
            return deleteUserSuccess(state, action);
        case actions.DELETE_USER_FAIL:
            return deleteUserFail(state, action);

        case actions.GET_USER_CLEAN:
            return getUserClean(state, action);

        default:
            return state;
    }
}

export default userListReducer;