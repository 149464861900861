import React, { useEffect, useState } from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from './utils/history';
import './static/css/style.less';
import { PrivateRoute } from './components/utils/privateRoute';
import AdminRouter from './routes/admin';
import AuthRouter from './routes/auth';
import { ThemeProvider } from 'styled-components';

const theme = require('./config/theme/theme');

require('dotenv').config();


function App() {
	const userLoggedIn = useSelector(state => state.auth.userLoggedIn);
	const [path, setPath] = useState(window.location.pathname);

	useEffect(() => {
		let unmounted = false;
		if(!unmounted) {
			setPath(window.location.pathname);
		}
		return () => {
			unmounted = true;
		}
	}, [setPath]);

	return (
		<ThemeProvider theme={{...theme}}>
		<Router history={history}>
			{!userLoggedIn ? <Route path="/" component={AuthRouter}/>
				: <PrivateRoute path="/admin" component={AdminRouter}/>}
			{userLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
				<Redirect from={path} to="/admin" />
				)}
		</Router>
		</ThemeProvider>
	);
}

export default App;