import axiosInstance from '../../utils/axios';
import { message } from 'antd';
import axios from 'axios';
import history from '../../utils/history';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_TOOLS_START = 'GET_TOOLS_START';
export const GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const GET_TOOLS_FAIL = 'GET_TOOLS_FAIL';

export const CREATE_TOOL_START = 'CREATE_TOOL_START';
export const CREATE_TOOL_SUCCESS = 'CREATE_TOOL_SUCCESS';
export const CREATE_TOOL_FAIL = 'CREATE_TOOL_FAIL';

export const UPDATE_TOOL_START = 'UPDATE_TOOL_START';
export const UPDATE_TOOL_SUCCESS = 'UPDATE_TOOL_SUCCESS';
export const UPDATE_TOOL_FAIL = 'UPDATE_TOOL_FAIL';

export const GET_TOOL_START = 'GET_TOOL_START';
export const GET_TOOL_SUCCESS = 'GET_TOOL_SUCCESS';
export const GET_TOOL_FAIL = 'GET_TOOL_FAIL';

export const DELETE_TOOL_START = 'DELETE_TOOL_START';
export const DELETE_TOOL_SUCCESS = 'DELETE_TOOL_SUCCESS'
export const DELETE_TOOL_FAIL = 'DELETE_TOOL_FAIL';

export const GET_TOOL_CLEAN = 'GET_TOOL_CLEAN';

export const getToolsStart = () => {
    return {
        type : GET_TOOLS_START
    }
}

export const getToolsSuccess = (json) => {
    return {
        type : GET_TOOLS_SUCCESS,
        data : json
    }
}

export const getToolsFail = (error) => {
    return {
        type : GET_TOOLS_FAIL,
        error : error
    }
}

export const createToolStart = () => {
    return {
        type : CREATE_TOOL_START
    }
}

export const createToolSuccess = (json) => {
    return {
        type : CREATE_TOOL_SUCCESS,
        data : json
    }
}

export const createToolFail = (error) => {
    return {
        type : CREATE_TOOL_FAIL,
        error : error
    }
}

export const updateToolStart = () => {
    return {
        type : UPDATE_TOOL_START
    }
}

export const updateToolSuccess = (json) => {
    return {
        type : UPDATE_TOOL_SUCCESS,
        data : json
    }
}

export const updateToolFail = (error) => {
    return {
        type : UPDATE_TOOL_FAIL,
        error : error
    }
}


export const getToolStart = () => {
    return {
        type : GET_TOOL_START
    }
}

export const getToolSuccess = (json) => {
    return {
        type : GET_TOOL_SUCCESS,
        data : json
    }
}

export const getToolFail = (error) => {
    return {
        type : GET_TOOL_FAIL,
        error : error
    }
}

export const deleteToolStart = () => {
    return {
        type : DELETE_TOOL_START
    }
}

export const deleteToolSuccess = (json) => {
    return {
        type : DELETE_TOOL_SUCCESS,
        data : json
    }
}

export const deleteToolFail = (error) => {
    return {
        type : DELETE_TOOL_FAIL,
        error : error
    }
}

export const getToolClean = () => {
    return {
        type: GET_TOOL_CLEAN
    }
}

var gPage;
var gSearch = '';
var gPageSize;

export const getToolList = (page , search, pageSize) => {
    gPage = page;
    gSearch = search; 
    gPageSize = pageSize;

    var lPage = (page && typeof page !== 'undefined' && page > 0) ? page : 1;
    var lpageSize = (pageSize && typeof pageSize !== 'undefined' && pageSize > 0) ? pageSize : 5;
    var lSearch = (search && typeof search !== 'undefined' && search.length > 0) ? search : "";

    let baseURL = `/api/inventory/tool/?page=${lPage}&page_size=${lpageSize}&search=${lSearch}`;

    return function(dispatch) {
        dispatch(getToolsStart());
        return axiosInstance.get(API_URL + baseURL)
            .then(res => {
                dispatch(getToolsSuccess(res.data));
            })
            .catch(error => {  
                if(error.response.status === 404 && gPage > 1) {
                    dispatch(getToolsFail(null));
                    var num = gPage - 1;
                    dispatch(getToolList(num, gSearch, gPageSize));
                } 
                else{
                    dispatch(getToolsFail(error)); 
                }
            })
    }
}

export const createTool = (data) => {
    return function(dispatch) {
        dispatch(createToolStart());
        return axios({
            method: "post",
            url: `${API_URL}/api/inventory/tool/`,
            data: data,
            headers: {
                "Authorization": localStorage.getItem('access_token')
                    ? 'Bearer ' + localStorage.getItem('access_token')
                    : null,
                "Content-Type": "multipart/form-data" },
        }).then(res => {
                dispatch(createToolSuccess(res.data));
                dispatch(getToolList(gPage, gSearch, gPageSize));
                message.success('Tool added successfully');
            })
            .catch(error => {
                dispatch(createToolFail(error));
                if (error.response.status === 401) {
                    localStorage.clear();
                    history.push("/");
                }
                else if (error.response.status === 400) {
                    if (error.response.data.image && error.response.data.image !== '')
                        message.error('Main image: filename cannot exceed 100 characters');
                    else if (error.response.data.sub_image_1 && error.response.data.sub_image_1 !== '')
                        message.error('sub image 1: filename cannot exceed 100 characters');
                    else if (error.response.data.sub_image_2 && error.response.data.sub_image_2 !== '')
                        message.error('sub image 2: filename cannot exceed 100 characters');
                    else if (error.response.data.sub_image_3 && error.response.data.sub_image_3 !== '')
                        message.error('sub image 3: filename cannot exceed 100 characters');
                }
                else
                    message.error('something went wrong');
                if (error.response && error.response.data.tool_id && error.response.data.tool_id !== '')
                    message.error('Tool id required');
                else if(error.response && error.response.data.message && error.response.data.message !== '')
                    message.error('Tool id already exist');
            })
    }
}

export const updateTool = (data, id) => {
    return function(dispatch) {
        dispatch(updateToolStart());
        return axiosInstance.put(`${API_URL}/api/inventory/tool/${id}/`, data)
            .then(res => {
                dispatch(updateToolSuccess(res.data));
                dispatch(getToolList(gPage, gSearch, gPageSize));
                message.success('Tool updated successfully');
            })
            .catch(error => {
                dispatch(updateToolFail(error));
                if(error.response && error.response.data.message && error.response.data.message !== '')
                    message.error('Tool id already exist');
                else
                    message.error('something went wrong');
            })
    }
}

export const getTool = (id) => {
    return function(dispatch) {
        dispatch(getToolStart());
        return axiosInstance.get(`${API_URL}/api/inventory/tool/${id}/`)
            .then(res => {
                dispatch(getToolSuccess(res.data));
            })
            .catch(error => {
                dispatch(getToolFail(error));
            })
    }
}

export const deleteTool = (id) => {
    return function(dispatch) {
        dispatch(deleteToolStart());
        return axiosInstance.delete(`${API_URL}/api/inventory/tool/${id}/`)
            .then(res => {
                dispatch(deleteToolSuccess(id));
                dispatch(getToolList(gPage, gSearch, gPageSize));
                message.success('Tool deleted successfully');
            })
            .catch(error => {
                dispatch(deleteToolFail(error));
                message.error('something went wrong');
            })
    }
}