import axiosInstance from '../../utils/axios';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_BOOKINGS_START = 'GET_BOOKINGS_START';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL';

export const GET_BOOKING_START = 'GET_BOOKING_START';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAIL = 'GET_BOOKING_FAIL';

export const DELETE_BOOKING_START = 'DELETE_BOOKING_START';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAIL = 'DELETE_BOOKING_FAIL';

export const ADMIN_TO_USER_START = 'ADMIN_TO_USER_START';
export const ADMIN_TO_USER_SUCCESS = 'ADMIN_TO_USER_SUCCESS';
export const ADMIN_TO_USER_FAIL = 'ADMIN_TO_USER_FAIL';

export const GET_PRE_BOOKING_START  = 'GET_PRE_BOOKING_START';
export const GET_PRE_BOOKING_SUCCESS = 'GET_PRE_BOOKING_SUCCESS';
export const GET_PRE_BOOKING_FAIL = 'GET_PRE_BOOKING_FAIL';

export const PRE_BOOKING_APPROVAL_START = 'PRE_BOOKING_APPROVAL_START';
export const PRE_BOOKING_APPROVAL_SUCCESS = 'PRE_BOOKING_APPROVAL_SUCCESS';
export const PRE_BOOKING_APPROVAL_FAIL = 'PRE_BOOKING_APPROVAL_FAIL';


export const getBookingsStart = () => {
    return {
        type : GET_BOOKINGS_START
    }
}

export const getBookingsSuccess = (json) => {
    return {
        type : GET_BOOKINGS_SUCCESS,
        data : json
    }
}

export const getBookingsFail = (error) => {
    return {
        type : GET_BOOKINGS_FAIL,
        error : error
    }
}

export const getBookingStart = () => {
    return {
        type : GET_BOOKING_START
    }
}

export const getBookingSuccess = (json) => {
    return {
        type : GET_BOOKING_SUCCESS,
        data : json
    }
}

export const getBookingFail = (error) => {
    return {
        type : GET_BOOKING_FAIL,
        error : error
    }
}

export const deleteBookingStart = () => {
    return {
        type : DELETE_BOOKING_START
    }
}

export const deleteBookingSuccess = (json) => {
    return {
        type : DELETE_BOOKING_SUCCESS,
        data : json
    }
}

export const deleteBookingFail = (error) => {
    return {
        type : DELETE_BOOKING_FAIL,
        error : error
    }
}

export const adminToUserStart = () => {
    return {
        type : ADMIN_TO_USER_START
    }
}

export const adminToUserSuccess = (json) => {
    return {
        type : ADMIN_TO_USER_SUCCESS,
        data : json
    }
}

export const adminToUserFail = (error) => {
    return {
        type : ADMIN_TO_USER_FAIL,
        error : error
    }
}

export const adminToUserResponseClean = () => {
	return {
		type : 'RESPONSE_CLEAN'
	}
}

export const getPreBookingStart = () => {
    return {
        type : GET_PRE_BOOKING_START,
    }
}

export const getPreBookingSuccess = (json) => {
    return {
        type : GET_PRE_BOOKING_SUCCESS,
        data : json
    }
}

export const getPreBookingFail = (error) => {
    return {
        type : GET_PRE_BOOKING_FAIL,
        error : error
    }
}

export const preBookingApprovalStart = () => {
    return {
        type : PRE_BOOKING_APPROVAL_START,
    }
}

export const preBookingApprovalSuccess = (json) => {
    return {
        type : PRE_BOOKING_APPROVAL_SUCCESS,
        data : json
    }
}

export const preBookingApprovalFail = (error) => {
    return {
        type : PRE_BOOKING_APPROVAL_FAIL,
        error : error
    }
}

var gPage;
var gSearch = '';
var gPageSize;

export const getBookingList = (page , search, pageSize) => {
    gPage = page;
    gSearch = search; 
    gPageSize = pageSize;

    var lPage = (page && typeof page !== 'undefined' && page > 0) ? page : 1;
    var lpageSize = (pageSize && typeof pageSize !== 'undefined' && pageSize > 0) ? pageSize : 5;
    var lSearch = (search && typeof search !== 'undefined' && search.length > 0) ? search : "";

    let baseURL = `/api/booking/booking_history/?page=${lPage}&page_size=${lpageSize}&search=${lSearch}`;

    return function(dispatch) {
        dispatch(getBookingsStart());
        return axiosInstance.get(API_URL + baseURL)
            .then(res => {
                dispatch(getBookingsSuccess(res.data));
            })
            .catch(error => {
                dispatch(getBookingsFail(error));
                if(error.response.status === 404 && gPage > 1) {
                    var num = gPage - 1;
                    dispatch(getBookingList(num, gSearch, gPageSize));
                }  
            })
    }
}

export const getBooking = (id) => {
    return function(dispatch) {
        dispatch(getBookingStart());
        return axiosInstance.get(`${API_URL}/api/booking/booking_history/${id}/`)
            .then(res => {
                dispatch(getBookingSuccess(res.data));
            })
            .catch(error => {
                dispatch(getBookingFail(error));
            })
    }
}

export const deleteBooking = (id) => {
    return function(dispatch) {
        dispatch(deleteBookingStart());
        return axiosInstance.delete(`${API_URL}/api/booking/${id}/delete_history/`)
            .then(res => {
                message.success(`Successfully deleted`);
                dispatch(deleteBookingSuccess(id));
                dispatch(getBookingList(gPage, gSearch, gPageSize));
            })
            .catch(error => {
                message.error('something went wrong'); 
                dispatch(deleteBookingFail(error));
            })
    }
}

export const leaderToUserBooking = (data) => {
    return function(dispatch) {
        dispatch(adminToUserStart());
        return axiosInstance.post(`${API_URL}/api/booking/requests/leader_to_user/`, data)
            .then(res => {	
				dispatch(adminToUserSuccess(res.data));
                dispatch(getBookingList(gPage, gSearch, gPageSize));
            })
            .catch(error => {
                dispatch(adminToUserFail(error));
                if(error.response && error.response.data.message && error.response.data.message!=='')
                    message.error(error.response.data.message);
                else
                    message.error('something went wrong');              
            })
    }
}

export const responseClean = () => {
	return function(dispatch) {
		dispatch(adminToUserResponseClean());
	}
}

export const getPreBooking = () => {
    return function(dispatch) {
        dispatch(getPreBookingStart);
        return axiosInstance.get(`${API_URL}/api/booking/approvals/special_tool_pending/`)
            .then(res => {
                dispatch(getPreBookingSuccess(res.data));
            })
            .catch(error => {
                dispatch(getPreBookingFail(error));
            })
    }
}

export const preBookingAproval = (id) => {
    return function(dispatch) {
        dispatch(preBookingApprovalStart());
        return axiosInstance.get(`${API_URL}/api/booking/approvals/${id}/special_tool_approval/`)
            .then(res => {
                dispatch(preBookingApprovalSuccess(res.data));
                message.success('Prebooking has approved');
            })
            .catch(error => {
                dispatch(preBookingApprovalFail(error));
                if(error.response && error.response.data.message && error.response.data.message!=='')
                    message.error(error.response.data.message);
                else
                    message.error('Something went wrong');
            })
    }
}
