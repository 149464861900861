import * as actions from './actions';

const initState = {
    notifications: [],
    error: null,
    isLoading: false
};

const getnewNotifStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getnewNotifSuccess = (state, action) => {
    return {
        ...state,
        notifications: action.data,
        error: null,
        isLoading: false
    }
}

const getnewNotifFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const addNewNotification = (state, action) => {
    return {
        ...state,
        notifications: [...state.notifications, action.data]
    }
}

const notificationReaded = (state, action) => {
    return {
        ...state,
        notifications: state.notifications.filter(notif => notif.id !== action.data)
    }
}

// 

const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_NEW_NOTIF_START:
            return getnewNotifStart(state, action);
        case actions.GET_NEW_NOTIF_SUCCESS:
            return getnewNotifSuccess(state, action);
        case actions.GET_NEW_NOTIF_FAIL:
            return getnewNotifFail(state, action);
        case 'ADD_NEW_NOTIF':
            return addNewNotification(state, action);
        case 'NOTIF_READED':
            return notificationReaded(state, action);

        default:
            return state;
    }
}

export default notificationReducer;