import React, { useState, useEffect } from 'react';
import { Avatar, notification } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Notification from './notification';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../../redux/auth/actions';
import avatar from '../../../static/img/avatar/avatar.png';
import ChangePasswordModal from './changePassword';
import { onMessageListener } from '../../../firebase';
import { SmileOutlined } from '@ant-design/icons';

import { getNewNotifications, markAsRead, addNewNotification } from '../../../redux/notification/actions';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const notifications = useSelector(state => state.notification.notifications);
  const [isLeader, setIsLeader] = useState(false);

	useEffect(() => {
		setIsLeader(JSON.parse(localStorage.getItem('leader')));
	}, []);

  useEffect(() => {
    dispatch(getNewNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessageListener()
	.then((payload) => {
	  notification.open({
		message: payload.data.title,
		description: payload.data.body,
		icon: <SmileOutlined style={{ color: '#108ee9' }} />,
	  });
	  dispatch(addNewNotification(payload.data));
	})
	.catch((err) => console.log("failed: ", err));

  const SignOut = e => {
    e.preventDefault();
    dispatch(userLogout());
  };

  const notifReaded = (id) => {
    dispatch(markAsRead(id));
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Makino</Heading>
            {isLeader === false ? <p>Admin</p> : <p>Leader</p>}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li onClick={() => setVisible(true)}>
            <Link to="#">
              <FeatherIcon icon="settings"/> Change Password
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <>
      <InfoWraper>
        <Notification notifications={notifications} markAsRead={notifReaded}/>
        <div className="nav-author">
          <Popover placement="bottomRight" content={userContent} action="hover">
            <Link to="#" className="head-example">
              <Avatar src={avatar} />
            </Link>
          </Popover>
        </div>
      </InfoWraper>
      <ChangePasswordModal visible={visible} closeModal={() => setVisible(false)}/>
    </>
  );
};

export default AuthInfo;
