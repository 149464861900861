import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Table, Tag, message, Row, Col } from 'antd';
import axiosInstance from '../../utils/axios';
import { UserTableStyleWrapper } from '../employee/style';
import { TableWrapper, CardToolbox, Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { AutoComplete } from '../../components/autoComplete/autoComplete';

const API_URL = process.env.REACT_APP_API_URL;

function CountTable() {
    const { type } = useParams();
    const [items, setIems] = useState([]);
    const [loading, setLoading] = useState(false);
    const toolsTableData = [];

    useEffect(() => {
      if(type === "issued")
          getIssuedItems('');
      if(type === "nonissued")
          getNonIssuedItems('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIssuedItems = (searchQuery) => {
        setLoading(true);
        axiosInstance.get(API_URL + `/api/dashboard/counts/get_issued_items/?search=${searchQuery}`)
            .then(res => {
                setIems(res.data);
                setLoading(false);
            })
            .catch(error => {  
                setLoading(false);
                message.error('Something went wrong!');
            })
    }

    const getNonIssuedItems = (searchQuery) => {
        setLoading(true);
        axiosInstance.get(API_URL + `/api/dashboard/counts/get_non_issued_items/?search=${searchQuery}`)
            .then(res => {
                setIems(res.data);
                setLoading(false);
            })
            .catch(error => {  
                setLoading(false);
                message.error('Something went wrong!');
            })
    }

    items && items.map(tool => {
        return toolsTableData.push({
          key: tool.id,
          tool_name: tool.tool_name,
          tool_id: tool.tool_id ,
          owner: tool.owner,
          reg_date: tool.reg_date,
          calib_due_date: ( tool.calib_req ? tool.calib_due_date : null ) ,
          std_calib_period: ( tool.calib_req ? `${tool.std_calib_period} months` : null ) ,
          available: (
            tool.decommissioned ? (<Tag color="#cd201f">decommissioned</Tag>) :
            (tool.available ? (<Tag  color="#87d068">Active</Tag>) 
              : (<Tag color="#cd201f">Hidden</Tag>))
          ),
        });
      });
    
  const toolsTableColumns = [
    {
      title: 'ID',
      dataIndex: 'tool_id',
      key: 'tool_id',
    },
    {
      title: 'Tool name',
      dataIndex: 'tool_name',
      key: 'tool_name',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Registration date',
      dataIndex: 'reg_date',
      key: 'reg_date',
    },
    {
      title: 'Caliberation due',
      dataIndex: 'calib_due_date',
      key: 'calib_due_date',
    },
    {
      title: 'Caliberation period',
      dataIndex: 'std_calib_period',
      key: 'std_calib_period',
    },
    {
      title: 'Tool status',
      dataIndex: 'available',
      key: 'available',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action'
    }
  ];
  
    const handleSearch = searchText => {
      if(type === "issued")
        getIssuedItems(searchText);
      if(type === "nonissued")
        getNonIssuedItems(searchText);
    };
  
    return (
      <>
        <CardToolbox>
        	<PageHeader
          		ghost
          		title= {(type === "issued") ? "Issued items" : "Non issued items"}
          		subTitle={
                <>
                  <span className="title-counter">{ items && items.length } Items </span>
                    <AutoComplete
                      onSearch={handleSearch}
                      dataSource={[]}
                      placeholder="Search..."
                      width="100%"
                      patterns
                    />
                </>
              }
          />
        </CardToolbox>
        <Main>
          <Row gutter={15}>
          	<Col md={24}>
              <Cards headless>
                <UserTableStyleWrapper>
                    <TableWrapper className="table-responsive">
                    <Table
                        dataSource={toolsTableData}
                        columns={toolsTableColumns}
                        loading={loading}
                        pagination={{
                          showSizeChanger: true,
                          pageSizeOptions: [5, 10, 20, 30, 50],
                          defaultPageSize: 5,
                          total: toolsTableData.length,
                          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                    </TableWrapper>
                </UserTableStyleWrapper>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
        
    );
}

export default CountTable;
