import React, { useState } from 'react';
import { Modal, Button, Form, Input, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authClean, changePassword } from '../../../redux/auth/actions';


function ChangePasswordModal(props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { isLoading, error, passwordChangeSuccess } = useSelector(state => {
    	return {
			isLoading: state.auth.isLoading,
			error: state.auth.error,
            passwordChangeSuccess: state.auth.passwordChangeSuccess
    	};
  	});

    const [state, setState] = useState({
        old_password: null,
        new_password: null
    });

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const handleCreate = () => {
        dispatch(changePassword(state));
    }

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

    const handleCancel = () => {
        form.resetFields();
        setState({...state, old_password: null, new_password: null});
        props.closeModal();
        dispatch(authClean());
    }

    return (
    	<>
			<Modal
				visible={props.visible}
				title="Change password"
				centered
				onCancel={handleCancel}
				footer={[
					<Form form={form} onFinish={handleCreate} onFinishFailed={onFinishFailed}>  
                        <Button 
                            key="Create" 
                            type="primary" 
                            loading={isLoading} 
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                        <Button key="Cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
					</Form>       
				]}
			>

				<Form form={form}>

                    { error !== null ?
                    <Alert
                        message="Old password is incorrect"
                        type="error"
                        closable
                        showIcon
                    />
                    : null }

                    { passwordChangeSuccess === true ?
                    <Alert
                        message="Password changed sucessfully"
                        type="success"
                        closable
                        showIcon
                    />
                    : null }<br/>

                    <Form.Item
                        placeholder="Enter current password"
                        name="old_password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input current password!',
                        },
                        ]}
                    >
                        <Input.Password 
                            name="old_password" 
                            onChange={handleChange}
                            placeholder="Enter current password"
                        />
                    </Form.Item>		

                    <Form.Item
                        name="new_password"
                        placeholder="Enter new password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input new password!',
                        },
                        ]}
                    >
                        <Input.Password 
                            name="new_password" 
                            onChange={handleChange}
                            placeholder="Enter new password"
                        />
                    </Form.Item>		
				</Form>
    		</Modal>
        </>
    )
}

export default ChangePasswordModal;