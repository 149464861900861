import history from '../../utils/history';
import axiosInstance from '../../utils/axios'
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const PASSWORD_RESET_CONFIRM_START = 'PASSWORD_RESET_CONFIRM_START';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const AUTH_CLEAN = 'AUTH_CLEAN';

export const ADMIN_PROFILE_START = 'ADMIN_PROFILE_START';
export const ADMIN_PROFILE_SUCCESS = 'ADMIN_PROFILE_SUCCESS';
export const ADMIN_PROFILE_FAIL = 'ADMIN_PROFILE_FAIL';

export const authStart = () => {
    return {
        type: AUTH_START
    }
}

export const authSuccess = (json) => {
    return {
        type: AUTH_SUCCESS,
        data: json
    }
}

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    }
}

export const logoutStart = () => {
    return {
        type: LOGOUT_START
    }
}

export const logoutSuccess = (json) => {
    return {
        type: LOGOUT_SUCCESS,
        data: json
    }
}

export const logoutFail = (error) => {
    return {
        type: LOGOUT_FAIL,
        error: error
    }
}

export const passwordResetStart = () => {
    return {
        type: PASSWORD_RESET_START
    }
}

export const passwordResetSuccess = (json) => {
    return {
        type: PASSWORD_RESET_SUCCESS,
        data: json
    }
}

export const passwordResetFail = (error) => {
    return {
        type: PASSWORD_RESET_FAIL,
        error: error
    }
}

export const passwordResetConfirmStart = () => {
    return {
        type: PASSWORD_RESET_CONFIRM_START
    }
}

export const passwordResetConfirmSuccess = (json) => {
    return {
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
        data: json
    }
}

export const passwordResetConfirmFail = (error) => {
    return {
        type: PASSWORD_RESET_CONFIRM_FAIL,
        error: error
    }
}
 
export const passwordChangeStart = () => {
    return {
        type: CHANGE_PASSWORD_START
    }
}

export const passwordChangeSuccess = (json) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        data: json
    }
}

export const passwordChangeFail = (error) => {
    return {
        type: CHANGE_PASSWORD_FAIL,
        error: error
    }
}

export const authClean = () => {
    return {
        type: AUTH_CLEAN
    }
}

export const adminProfileStart = () => {
    return {
        type: ADMIN_PROFILE_START
    }
}

export const adminProfileSuccess = (json) => {
    return {
        type: ADMIN_PROFILE_SUCCESS,
        data: json
    }
}

export const adminProfileFail = (error) => {
    return {
        type: ADMIN_PROFILE_FAIL,
        error: error
    }
}

export const userLogin = (loginCred) => {
    return function(dispatch) {
        dispatch(authStart());
        return axiosInstance.post(`${API_URL}/api/users/user_login/`, loginCred)
            .then(res => {
                const user = res.data;
                if (user.user_role === 'admin' || user.leader === true) {
                    var stat = setUser(user)
					if(stat) {
                        dispatch(authSuccess(res.data));
                        history.push("/admin");
					}
                } else {
                    dispatch(authFail({'message': 'auth fails'}));
                }
            })
            .catch(error => {
                dispatch(authFail(error));
            })
    }
}

export const userLogout = () => {
    return function(dispatch) {
        dispatch(logoutStart());
        return axiosInstance.post(`${API_URL}/api/users/user_logout/`, {})
            .then(res => {
                dispatch(logoutSuccess(res));
                localStorage.clear();
                history.push("/");
            })
            .catch(error => {
                dispatch(logoutFail(error));
            })
    }
}

export const passwordReset = (data) => {
    return function(dispatch) {
        dispatch(passwordResetStart());
        return axiosInstance.post(`${API_URL}/auth/users/reset_password/`, data)
            .then(res => {
                dispatch(passwordResetSuccess(res.data));
                message.success('Please check your inbox');
            })
            .catch(error => {
                dispatch(passwordResetFail(error));
                message.error('something went wrong');
            })
    }
}

export const passwordResetConfirm = (data) => {
    return function(dispatch) {
        dispatch(passwordResetConfirmStart());
        return axiosInstance.post(`${API_URL}/auth/users/reset_password_confirm/`, data)
            .then(res => {
                dispatch(passwordResetConfirmSuccess(res.data));
                message.success('Password changed successfully');
            })
            .catch(error => {
                dispatch(passwordResetConfirmFail(error));
                if(error.response && error.response.data.new_password && error.response.data.new_password!=='')
                    message.error(error.response.data.new_password);
                else if(error.response && error.response.data.token && error.response.data.token)
                    message.error('Session expired, Try again later');
                else
                    message.error('Something went wrong');
            })
    }
}

export const changePassword = (data) => {
    return function(dispatch) {
        dispatch(passwordChangeStart());
        return axiosInstance.put(`${API_URL}/api/users/change-password/0/`, data)
            .then(res => {
                dispatch(passwordChangeSuccess(res.data));
            })
            .catch(error => {
                dispatch(passwordChangeFail(error));
            })
    }
}

export const getProfile = () => {
    return function(dispatch) {
        dispatch(adminProfileStart());
        return axiosInstance.get(`${API_URL}/api/users/admin-profile/1/`)
            .then(res => {
                dispatch(adminProfileSuccess(res.data));
            })
            .catch(error => {
                dispatch(adminProfileFail(error));
            })
    }
}

const setUser = (user) => {
    axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + user.access_token;
    localStorage.setItem('leader', user.leader);
    localStorage.setItem('access_token', user.access_token);
    localStorage.setItem('refresh_token', user.refresh_token);
    localStorage.setItem('role', user.user_role);
    localStorage.setItem('loggedIn', true);
    localStorage.setItem('employee_id', user.employee_id);
    localStorage.setItem('user_id', user.user_id);
	return true;
}
