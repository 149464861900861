import * as actions from './actions';

const initState = {
    authData: null,
    error: null,
    isLoading: false,
    userLoggedIn: localStorage.getItem('loggedIn') ? true : false,
    resetSuccess: false,
    passwordChangeSuccess: false,
    profile: {}
};

const authStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        userLoggedIn: false
    }
}

const authSuccess = (state, action) => {
    return {
        ...state,
        authData: action.data,
        error: null,
        isLoading: false,
        userLoggedIn: true
    }
}

const authFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        userLoggedIn: false
    }
}

const logoutStart = (state, action) => {
    return {
        ...state,
        isLoading: true
    }
}

const logoutSuccess = (state, action) => {
    return {
        ...state,
        authData: null,
        error: null,
        isLoading: false,
        userLoggedIn: false
    }
}

const logoutFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const passwordResetStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        error: null,
    }
}

const passwordResetSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
    }
}

const passwordResetFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const passwordResetConfirmStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        error: null,
        resetSuccess: false
    }
}

const passwordResetConfirmSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        resetSuccess: true
    }
}

const passwordResetConfirmFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
    }
}

const passwordChangeStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        passwordChangeSuccess: false,
        error: null
    }
}

const passwordChangeSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        passwordChangeSuccess: true,
        isLoading: false
    }
}

const passwordChangeFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        passwordChangeSuccess: false,
        isLoading: false,
    }
}

const authClean = (state, action) => {
    return {
        ...state,
       passwordChangeSuccess: false,
       error: null
    }
}

const profileStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true
    }
}

const profileSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        profile: action.data
    }
}

const profileFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}


const loginReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AUTH_START:
            return authStart(state, action);
        case actions.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actions.AUTH_FAIL:
            return authFail(state, action);

        case actions.LOGOUT_START:
            return logoutStart(state, action);
        case actions.LOGOUT_SUCCESS:
            return logoutSuccess(state, action);
        case actions.LOGOUT_FAIL:
            return logoutFail(state, action);

        case actions.PASSWORD_RESET_START:
            return passwordResetStart(state, action);
        case actions.PASSWORD_RESET_SUCCESS:
            return passwordResetSuccess(state, action);
        case actions.PASSWORD_RESET_FAIL:
            return passwordResetFail(state, action);

        case actions.PASSWORD_RESET_CONFIRM_START:
            return passwordResetConfirmStart(state, action);
        case actions.PASSWORD_RESET_CONFIRM_SUCCESS:
            return passwordResetConfirmSuccess(state, action);
        case actions.PASSWORD_RESET_CONFIRM_FAIL:
            return passwordResetConfirmFail(state, action);

        case actions.CHANGE_PASSWORD_START:
            return passwordChangeStart(state, action);
        case actions.CHANGE_PASSWORD_SUCCESS:
            return passwordChangeSuccess(state, action);
        case actions.CHANGE_PASSWORD_FAIL:
            return passwordChangeFail(state, action);

        case actions.AUTH_CLEAN:
            return authClean(state, action);

        case actions.ADMIN_PROFILE_START:
            return profileStart(state, action);
        case actions.ADMIN_PROFILE_SUCCESS:
            return profileSuccess(state, action);
        case actions.ADMIN_PROFILE_FAIL:
            return profileFail(state, action);

        default:
            return state;
    }
}

export default loginReducer;