/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
//import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import MenueItems from './menuItems';
import { Div, SmallScreenAuthInfo } from './style';
import AuthInfo from '../components/utils/auth-info/info';

import leftSvg from '../static/img/icon/left.svg';
import rightSvg from '../static/img/icon/right.svg';
import logo from '../static/img/makino.png';


const { Header, Footer, Sider, Content } = Layout; 

const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,     
        activeSearch: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
 
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      /*const onShowHide = () => {
        this.setState({
          hide: !hide
        });
      };*/

      const footerStyle = {
        position: 'sticky',
        bottom: 0,
        padding: '20px 30px 18px',
        color: 'rgba(255, 255, 255, 255)',
        fontSize: '14px',
        background: '#373B40',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: '#F1F2F6',
          left: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          right: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      return (
        <Div>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
              }}
            >
              <Row>
                <Col lg={4} sm={6} xs={12} className="align-center-v navbar-brand">
                    <button onClick={toggleCollapsed} className="menu-btn">
                      <img src={collapsed ? rightSvg : leftSvg} alt="menu" />
                    </button>
                  <Link
                    className='striking-logo'
                    to="/admin"
                  >
                    <img src={logo} alt="" height="30"/>
                  </Link>
                </Col>

                <Col lg={14} md={8} sm={0} xs={0}>
                   
                </Col>

                <Col lg={6} md={10} sm={12} xs={12}>
                    <AuthInfo />
                </Col>

               {/* <Col md={0} sm={18} xs={12}>
                    <div className="mobile-action">
                      <Link className="btn-auth" onClick={onShowHide} to="#">
                        <FeatherIcon icon="more-vertical" />
                      </Link>
                    </div>
                </Col>*/}
              </Row>
            </Header>
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenAuthInfo hide={hide}>
                      <AuthInfo/>
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={'light'}>
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  renderThumbHorizontal={renderThumbHorizontal}
                  renderThumbVertical={renderThumbVertical}
                  renderView={renderView}
                  renderTrackVertical={renderTrackVertical}
                >
                  <p className="sidebar-nav-title">MAIN MENU</p>
                  <MenueItems
                    toggleCollapsed={toggleCollapsedMobile}
                  />
                </Scrollbars>
              </Sider>
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                  <Footer className="admin-footer" style={footerStyle}>
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">Copyright @Techbyheart</span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          {/* <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink> ©*/}
                        </div>
                      </Col>
                    </Row>
                  </Footer>
                </Content>
              </Layout>
            </Layout>
          </Layout>                    
        </Div>
      );
    }
  }

  return LayoutComponent;
};
export default ThemeLayout;
