import axiosInstance from '../../utils/axios';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const GET_USER_CLEAN = 'GET_USER_CLEAN';

export const getUsersStart = () => {
    return {
        type : GET_USERS_START
    }
}

export const getUsersSuccess = (json) => {
    return {
        type : GET_USERS_SUCCESS,
        data : json
    }
}

export const getUsersFail = (error) => {
    return {
        type : GET_USERS_FAIL,
        error : error
    }
}

export const createUserStart = () => {
    return {
        type : CREATE_USER_START
    }
}

export const createUserSuccess = (json) => {
    return {
        type : CREATE_USER_SUCCESS,
        data : json
    }
}

export const createUserFail = (error) => {
    return {
        type : CREATE_USER_FAIL,
        error : error
    }
}

export const getUserStart = () => {
    return {
        type : GET_USER_START
    }
}

export const getUserSuccess = (json) => {
    return {
        type : GET_USER_SUCCESS,
        data : json
    }
}

export const getUserFail = (error) => {
    return {
        type : GET_USER_FAIL,
        error : error
    }
}

export const updateUserStart = () => {
    return {
        type : UPDATE_USER_START
    }
}

export const updateUserSuccess = (json) => {
    return {
        type : UPDATE_USER_SUCCESS,
        data : json
    }
}

export const updateUserFail = (error) => {
    return {
        type : UPDATE_USER_FAIL,
        error : error
    }
}

export const deleteUserStart = () => {
    return {
        type : DELETE_USER_START
    }
}

export const deleteUserSuccess = (json) => {
    return {
        type : DELETE_USER_SUCCESS,
        data : json
    }
}

export const deleteUserFail = (error) => {
    return {
        type : DELETE_USER_FAIL,
        error : error
    }
}

export const getUserClean = () => {
    return {
        type: GET_USER_CLEAN
    }
}

export const getUserList = (search) => {
    let searchQuery = '';

    if(search && typeof search !== 'undefined' && search.length > 0) {
        searchQuery = search;
    } else {
        searchQuery = '';
    }
    return function(dispatch) {
        dispatch(getUsersStart());
        return axiosInstance.get(`${API_URL}/api/users/employees/?search=${searchQuery}`)
            .then(res => {
                dispatch(getUsersSuccess(res.data));
            })
            .catch(error => {
                dispatch(getUsersFail(error));
            })
    }
}

export const createUser = (userdata) => {
    return function(dispatch) {
        dispatch(createUserStart());
        return axiosInstance.post(`${API_URL}/api/users/employees/`, userdata)
            .then(res => {
                dispatch(createUserSuccess(res.data));
                message.success('User created successfully');
                dispatch(getUserList(''));
            })
            .catch(error => {
                dispatch(createUserFail(error));
                if(error.response && error.response.data.employee_id && error.response.data.employee_id!=='')
                    message.error(error.response.data.employee_id);
                else if(error.response && error.response.data.email && error.response.data.email!=='')
                    message.error(error.response.data.email);
                else
                    message.error('something went wrong');
            })
    }
}

export const getUser = (id) => {
    return function(dispatch) {
        dispatch(getUserStart());
        return axiosInstance.get(`${API_URL}/api/users/employees/${id}/`)
            .then(res => {
                dispatch(getUserSuccess(res.data));
            })
            .catch(error => {
                dispatch(getUserFail(error));
            })
    }
}

export const updateUser = (userdata) => {
    return function(dispatch) {
        dispatch(updateUserStart());
        return axiosInstance.put(`${API_URL}/api/users/employees/${userdata.id}/`, userdata)
            .then(res => {
                dispatch(getUserList(''));
                dispatch(updateUserSuccess(res.data));
                message.success('User updated successfully');
            })
            .catch(error => {
                dispatch(updateUserFail(error));
                message.error('something went wrong');
            })
    }
}

export const deleteUser = (id) => {
    return function(dispatch) {
        dispatch(deleteUserStart());
        return axiosInstance.delete(`${API_URL}/api/users/employees/${id}/`)
            .then(res => {
                dispatch(deleteUserSuccess(id));
                message.success('User deleted successfully');
            })
            .catch(error => {
                dispatch(deleteUserFail(error));
                message.error('something went wrong');
            })
    }
}