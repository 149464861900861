import axiosInstance from '../../utils/axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_NEW_NOTIF_START = 'GET_NEW_NOTIF_START';
export const GET_NEW_NOTIF_SUCCESS = 'GET_NEW_NOTIF_SUCCESS';
export const GET_NEW_NOTIF_FAIL = 'GET_NEW_NOTIF_FAIL';


export const getNewNotifStart = () => {
    return {
        type : GET_NEW_NOTIF_START
    }
}

export const getNewNotifSuccess = (json) => {
    return {
        type : GET_NEW_NOTIF_SUCCESS,
        data : json
    }
}

export const getNewNotifFail = (error) => {
    return {
        type : GET_NEW_NOTIF_FAIL,
        error : error
    }
}

export const addNewNotification = (notification) => {
    return {
        type: 'ADD_NEW_NOTIF',
        data: notification
    }
}

export const notificationReaded = (id) => {
    return {
        type: 'NOTIF_READED',
        data: id
    }
}

export const notificationReadedSuccess = () => {
    return {
        type: 'NOTIF_READ_SUCCESS'
    }
}

export const getNewNotifications = () => {
    return function(dispatch) {
        dispatch(getNewNotifStart());
        return axiosInstance.get(`${API_URL}/api/notification/`)
            .then(res => {
                dispatch(getNewNotifSuccess(res.data));
            })
            .catch(error => {
                dispatch(getNewNotifFail(error));
            })
    }
}

export const markAsRead = (id) => {
    return function(dispatch) {
        dispatch(notificationReaded(id));
        if(typeof id !== 'undefined')
            return axiosInstance.get(`${API_URL}/api/notification/${id}/notif_readed/`)
                .then(res => {
                    dispatch(notificationReadedSuccess());
                })
                .catch(error => {
                    console.log(error);
                })
    }
}