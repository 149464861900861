import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

// const { SubMenu } = Menu;

const MenuItems = ({toggleCollapsed}) => {
  const { path } = useRouteMatch();
  const darkMode = false;
  const topMenu = false;

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* <SubMenu key="dashboard" icon={!topMenu && <FeatherIcon icon="home" />} title="Dashboard">
        <Menu.Item key="home">
          <NavLink onClick={toggleCollapsed} to={`/admin`}>
            Social Media
          </NavLink>
        </Menu.Item>
        <Menu.Item key="business">
          <NavLink onClick={toggleCollapsed} to={`${path}/business`}>
            Fintech / Business
          </NavLink>
        </Menu.Item>
        <Menu.Item key="performance">
          <NavLink onClick={toggleCollapsed} to={`${path}/performance`}>
            Site Performance
          </NavLink>
        </Menu.Item>
        <Menu.Item key="eco">
          <NavLink onClick={toggleCollapsed} to={`${path}/eco`}>
            Ecommerce
          </NavLink>
        </Menu.Item>
        <Menu.Item key="crm">
          <NavLink onClick={toggleCollapsed} to={`${path}/crm`}>
            CRM
          </NavLink>
        </Menu.Item>
        <Menu.Item key="sales">
          <NavLink onClick={toggleCollapsed} to={`${path}/sales`}>
            Sales Performance
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      <Menu.Item 
        key="dashboard"
        icon={
		  !topMenu && (
			<NavLink className="menuItem-iocn" to={'/admin'}>
				<FeatherIcon icon="home" />
			</NavLink>
		  )
		}
      >
        <NavLink onClick={toggleCollapsed} to={`/admin`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      <Menu.Item 
        key="dataTable"
        icon={!topMenu && (
		 <NavLink className="menuItem-iocn" to={`/admin/employee`}>
			<FeatherIcon icon="users" />
		</NavLink>
		)}
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/employee`}>
          Users Table
        </NavLink>
      </Menu.Item>
      
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`/admin/tools`}>
              <FeatherIcon icon="tool" />
            </NavLink>
          )
        }
        key="starter"
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/tools`}>
          Assets
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`/admin/category`}>
              <FeatherIcon icon="layers" />
            </NavLink>
          )
        }
        key="Category"
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/category`}>
          Category
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`/admin/issued-items`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          )
        }
        key="issuedItems"
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/issued-items`}>
          Issued Items
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`/admin/report`}>
              <FeatherIcon icon="archive" />
            </NavLink>
          )
        }
        key="Report"
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/report`}>
          Report
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`/admin/pre-booking`}>
              <FeatherIcon icon="plus-square" />
            </NavLink>
          )
        }
        key="preBooking"
      >
        <NavLink onClick={toggleCollapsed} to={`/admin/pre-booking`}>
          Pre-Booking
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func
};

export default MenuItems;
