import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import AuthLayout from '../containers/authentication';

const SignIn = lazy(() => import('../containers/authentication/signIn'));
const ForgotPassword = lazy(() => import('../containers/authentication/forgotPassword'));
const ResetPasswordConfirm = lazy(() => import('../containers/authentication/resetPasswordConfirm'));

const AuthRouter = () => {

    return(
        <Switch>
            <Suspense
                fallback={
                <div className="spin">
                    <Spin />
                </div>
                }
            >
                <Route exact path="/forgot-password" component={ ForgotPassword }/>
                <Route exact path="/password/reset/confirm/:uid/:token" component={ ResetPasswordConfirm }/>
                <Route exact path="/" component={ SignIn }/>
            </Suspense>
        </Switch>
    );

};

export default AuthLayout(AuthRouter);
