import axiosInstance from '../../utils/axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_DASHBOARD_START = 'GET_DASHBOARD_START';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';


export const getDashboardStart = () => {
    return {
        type : GET_DASHBOARD_START
    }
}

export const getDashboardSuccess = (json) => {
    return {
        type : GET_DASHBOARD_SUCCESS,
        data : json
    }
}

export const getDashboardFail = (error) => {
    return {
        type : GET_DASHBOARD_FAIL,
        error : error
    }
}


export const getDashboard = () => {
    return function(dispatch) {
        dispatch(getDashboardStart());
        return axiosInstance.get(`${API_URL}/api/dashboard/`)
            .then(res => {
                dispatch(getDashboardSuccess(res.data));
            })
            .catch(error => {
                dispatch(getDashboardFail(error));
            })
    }
}
