import { combineReducers } from 'redux';
import loginReducer from './auth/reducers';
import bookingReducer from './booking/reducers';
import categoryReducer from './category/reducers';
import dashboardReducer from './dashboard/reducers';
import userListReducer from './employee/reducers';
import notificationReducer from './notification/reducers';
import toolReducer from './tool/reducers';


const rootReducers = combineReducers({
    auth: loginReducer,
    user: userListReducer,
    tool: toolReducer,
    category: categoryReducer,
    booking: bookingReducer,
    notification: notificationReducer,
    dashboard: dashboardReducer
});

export default rootReducers;
