import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ThemeLayout from '../layout/withAdminLayout';
import CountTable from '../containers/dashboard/countTable';

const Dashboard = lazy(() => import('../containers/dashboard/dashboard'));
const UserList = lazy(() => import('../containers/employee/UserListDataTable'));
const ToolList = lazy(() => import('../containers/tool/ToolDataTable'));
const ToolDetail = lazy(() => import('../containers/tool/AssetView'));
const CategoryList = lazy(() => import('../containers/category/categoryDataTable'));
const IssuedList = lazy(() => import('../containers/issueditem/issuedDataTable'));
const ReportList = lazy(() => import('../containers/report/reportDataTable'));
const Profile = lazy(() => import('../containers/authentication/profile'));
const Prebooking = lazy(() => import('../containers/approval/preeBookingApproval'));

const AdminRouter = () => {
    return(
        <Switch>
            <Suspense
                fallback={
                <div className="spin">
                    <Spin />
                </div>
                }
            >
                <Route exact path="/admin/employee" component={ UserList }/>
                <Route exact path="/admin/tools" component={ ToolList }/>
                <Route exact path="/admin/tool-detail/:id" component={ToolDetail }/>
                <Route exact path="/admin/count-table/:type" component={ CountTable }/>
                <Route exact path="/admin/category" component={ CategoryList }/>
                <Route exact path="/admin/issued-items" component={ IssuedList }/>
                <Route exact path="/admin/report" component={ ReportList }/>
                <Route exact path="/admin/profile" component={ Profile }/>
                <Route exact path="/admin/pre-booking" component={ Prebooking }/>
                <Route exact path="/admin" component={ Dashboard }/>
            </Suspense>
        </Switch>
    );

};

export default ThemeLayout(AdminRouter);
