import React from 'react';
import { Row, Col } from 'antd';
import { Aside } from './style'; //Content
// import Heading from '../../components/heading/heading';
import background from '../../static/img/BG.png';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={8} xl={10} lg={12} md={12} xs={24}>
          <Aside style={{
            backgroundImage:`url(${background})`, 
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat"}}
          >
            <div className="auth-side-content">
              {/* <Content>
                <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" />
                <br />
                <br />
                <Heading as="h1">
                  StrikingDash React <br />
                  Web Application
                </Heading>
                Banner image position
              </Content> */}
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={12} lg={12} md={12} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
