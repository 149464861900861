import axiosInstance from '../../utils/axios';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const GET_CATEGORY_START = 'GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const GET_CATEGORY_CLEAN = 'GET_CATEGORY_CLEAN';


export const getCategoriesStart = () => {
    return {
        type : GET_CATEGORIES_START
    }
}

export const getCategoriesSuccess = (json) => {
    return {
        type : GET_CATEGORIES_SUCCESS,
        data : json
    }
}

export const getCategoriesFail = (error) => {
    return {
        type : GET_CATEGORIES_FAIL,
        error : error
    }
}

export const createCategoryStart = () => {
    return {
        type : CREATE_CATEGORY_START
    }
}

export const createCategorySuccess = (json) => {
    return {
        type : CREATE_CATEGORY_SUCCESS,
        data : json
    }
}

export const createCategoryFail = (error) => {
    return {
        type : CREATE_CATEGORY_FAIL,
        error : error
    }
}

export const getCategoryStart = () => {
    return {
        type : GET_CATEGORY_START
    }
}

export const getCategorySuccess = (json) => {
    return {
        type : GET_CATEGORY_SUCCESS,
        data : json
    }
}

export const getCategoryFail = (error) => {
    return {
        type : GET_CATEGORY_FAIL,
        error : error
    }
}

export const updateCategoryStart = () => {
    return {
        type : UPDATE_CATEGORY_START
    }
}

export const updateCategorySuccess = (json) => {
    return {
        type : UPDATE_CATEGORY_SUCCESS,
        data : json
    }
}

export const updateCategoryFail = (error) => {
    return {
        type : UPDATE_CATEGORY_FAIL,
        error : error
    }
}

export const deleteCategoryStart = () => {
    return {
        type : DELETE_CATEGORY_START
    }
}

export const deleteCategorySuccess = (json) => {
    return {
        type : DELETE_CATEGORY_SUCCESS,
        data : json
    }
}

export const deleteCategoryFail = (error) => {
    return {
        type : DELETE_CATEGORY_FAIL,
        error : error
    }
}

export const getCategoryClean = () => {
    return {
        type: GET_CATEGORY_CLEAN
    }
}

export const getCategoryList = (search) => {
    let searchQuery = '';

    if(search && typeof search !== 'undefined' && search.length > 0) {
        searchQuery = search;
    } else {
        searchQuery = '';
    }

    return function(dispatch) {
        dispatch(getCategoriesStart());
        return axiosInstance.get(`${API_URL}/api/inventory/category/category_with_number/?search=${searchQuery}`)
            .then(res => {
                dispatch(getCategoriesSuccess(res.data));
            })
            .catch(error => {
                dispatch(getCategoriesFail(error));
            })
    }
}

export const createCategory = (data) => {
    return function(dispatch) {
        dispatch(createCategoryStart());
        return axiosInstance.post(`${API_URL}/api/inventory/category/`, data)
            .then(res => {
                dispatch(createCategorySuccess(res.data));
                message.success('Category created successfully');
            })
            .catch(error => {
                dispatch(createCategoryFail(error));
                message.error('something went wrong');
            })
    }
}

export const getCategory = (id) => {
    return function(dispatch) {
        dispatch(getCategoryStart());
        return axiosInstance.get(`${API_URL}/api/inventory/category/${id}/`)
            .then(res => {
                dispatch(getCategorySuccess(res.data));
            })
            .catch(error => {
                dispatch(getCategoryFail(error));
            })
    }
}

export const updateCategory = (data) => {
    return function(dispatch) {
        dispatch(updateCategoryStart());
        return axiosInstance.put(`${API_URL}/api/inventory/category/${data.id}/`, data)
            .then(res => {
                dispatch(getCategoryList(''));
                dispatch(updateCategorySuccess(res.data));
                message.success('Category updated successfully');
            })
            .catch(error => {
                dispatch(updateCategoryFail(error));
                message.error('something went wrong');
            })
    }
}

export const deleteCategory = (id) => {
    return function(dispatch) {
        dispatch(deleteCategoryStart());
        return axiosInstance.delete(`${API_URL}/api/inventory/category/${id}/`)
            .then(res => {
                dispatch(deleteCategorySuccess(id));
                message.success('Category deleted successfully');
            })
            .catch(error => {
                dispatch(deleteCategoryFail(error));
                message.error('something went wrong');
            })
    }
}