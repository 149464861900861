import * as actions from './actions';

const initState = {
    categories: [],
    error: null,
    isLoading: false,
    postSuccess: false,
    category: null
};

const getCategoriesStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getCategoriesSuccess = (state, action) => {
    return {
        ...state,
        categories: action.data,
        error: null,
        isLoading: false
    }
}

const getCategoriesFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createCategoryStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        postSuccess: false
    }
}

const createCategorySuccess = (state, action) => {

    const newCat = {
        id: action.data.id,
        description: action.data.description ? action.data.description : '',
        name: action.data.name,
        num_of_items: 0
    };
    
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true,
        categories: [...state.categories, newCat]
    }
}

const createCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        postSuccess: false
    }
}

const getCategoryStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getCategorySuccess = (state, action) => {
    return {
        ...state,
        category: action.data,
        error: null,
        isLoading: false
    }
}

const getCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const updateCategoryStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        postSuccess: false
    }
}

const updateCategorySuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true
    }
}

const updateCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        postSuccess: false
    }
}

const deleteCategoryStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const deleteCategorySuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        categories: state.categories.filter(category => category.id !== action.data)
    }
}

const deleteCategoryFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getCategoryClean = (state, action) => {
    return {
        ...state,
        category: null,
        error: null
    }
}

const categoryReducer = (state = initState, action) => {
    switch (action.type) {

        case actions.GET_CATEGORIES_START:
            return getCategoriesStart(state, action);
        case actions.GET_CATEGORIES_SUCCESS:
            return getCategoriesSuccess(state, action);
        case actions.GET_CATEGORIES_FAIL:
            return getCategoriesFail(state, action);

        case actions.CREATE_CATEGORY_START:
            return createCategoryStart(state, action);
        case actions.CREATE_CATEGORY_SUCCESS:
            return createCategorySuccess(state, action);
        case actions.CREATE_CATEGORY_FAIL:
            return createCategoryFail(state, action);

        case actions.GET_CATEGORY_START:
            return getCategoryStart(state, action);
        case actions.GET_CATEGORY_SUCCESS:
            return getCategorySuccess(state, action);
        case actions.GET_CATEGORY_FAIL:
            return getCategoryFail(state, action);

        case actions.UPDATE_CATEGORY_START:
            return updateCategoryStart(state, action);
        case actions.UPDATE_CATEGORY_SUCCESS:
            return updateCategorySuccess(state, action);
        case actions.UPDATE_CATEGORY_FAIL:
            return updateCategoryFail(state, action);

        case actions.DELETE_CATEGORY_START:
            return deleteCategoryStart(state, action);
        case actions.DELETE_CATEGORY_SUCCESS:
            return deleteCategorySuccess(state, action);
        case actions.DELETE_CATEGORY_FAIL:
            return deleteCategoryFail(state, action);

        case actions.GET_CATEGORY_CLEAN:
            return getCategoryClean(state, action);

        default:
            return state;
    }
}

export default categoryReducer;