import * as actions from './actions';


const initState = {
    tools: {
        results: []
    },
    error: null,
    isLoading: false,
    postSuccess: false,
    deleteSuccess: false,
    tool: null
};

const getToolsStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getToolsSuccess = (state, action) => {
    return {
        ...state,
        tools: action.data,
        error: null,
        isLoading: false
    }
}

const getToolsFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const createToolStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const createToolSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true,
        tools: { 
            ...state.tools, 
            //results: [...state.tools.results, action.data],
            count: state.tools.count+1
        }
    }
}

const createToolFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getToolStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        tool: null
    }
}

const getToolSuccess = (state, action) => {
    return {
        ...state,
        tool: action.data,
        error: null,
        isLoading: false
    }
}

const getToolFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        tool: null
    }
}

const updateToolStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        postSuccess: false
    }
}

const updateToolSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        postSuccess: true,
    }
}

const updateToolFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const deleteToolStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        deleteSuccess: false
    }
}

const deleteToolSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        deleteSuccess: true
        // tools: { 
        //     ...state.tools, 
        //     results: state.tools.results.filter(item => item.id !== action.data)
        // }
    }
}

const deleteToolFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        deleteSuccess: false
    }
}

const getToolClean = (state, action) => {
    return {
        ...state,
        tool: null,
        postSuccess: false,
        error: null
    }
}

const toolReducer = (state = initState, action) => {
    switch (action.type) {
        
        case actions.GET_TOOLS_START:
            return getToolsStart(state, action);
        case actions.GET_TOOLS_SUCCESS:
            return getToolsSuccess(state, action);
        case actions.GET_TOOLS_FAIL:
            return getToolsFail(state, action);

        case actions.CREATE_TOOL_START:
            return createToolStart(state, action);
        case actions.CREATE_TOOL_SUCCESS:
            return createToolSuccess(state, action);
        case actions.CREATE_TOOL_FAIL:
            return createToolFail(state, action);

        case actions.GET_TOOL_START:
            return getToolStart(state, action);
        case actions.GET_TOOL_SUCCESS:
            return getToolSuccess(state, action);
        case actions.GET_TOOL_FAIL:
            return getToolFail(state, action);

        case actions.UPDATE_TOOL_START:
            return updateToolStart(state, action);
        case actions.UPDATE_TOOL_SUCCESS:
            return updateToolSuccess(state, action);
        case actions.UPDATE_TOOL_FAIL:
            return updateToolFail(state, action);

        case actions.DELETE_TOOL_START:
            return deleteToolStart(state, action);
        case actions.DELETE_TOOL_SUCCESS:
            return deleteToolSuccess(state, action);
        case actions.DELETE_TOOL_FAIL:
            return deleteToolFail(state, action);

        case actions.GET_TOOL_CLEAN:
            return getToolClean(state, action);

        default:
            return state;
    }
}

export default toolReducer;