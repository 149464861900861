import * as actions from './actions';

const initState = {
    bookings: {
        results: []
    },
    preBookings: [],
    bookingSuccess: false,
    preBookingSuccess: false,
    error: null,
    isLoading: false,
    booking: null,
    deleteSuccess: false,
	successResponse: null
};

const getBookingsStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getBookingsSuccess = (state, action) => {
    return {
        ...state,
        bookings: action.data,
        error: null,
        isLoading: false
    }
}

const getBookingsFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const getBookingStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getBookingSuccess = (state, action) => {
    return {
        ...state,
        booking: action.data,
        error: null,
        isLoading: false
    }
}

const getBookingFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}
 
const deleteBookingStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,

    }
}

const deleteBookingSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        deleteSuccess: true
    }
}

const deleteBookingFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const adminToUserStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        bookingSuccess: false,
    }
}

const adminToUserSuccess = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        bookingSuccess: true,
		successResponse: action.data
    }
}

const adminToUserFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false,
        bookingSuccess: false
    }
}

const getPreBookingStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        preBookingSuccess: false
    }
}

const getPreBookingSuccess = (state, action) => {
    return {
        ...state,
        preBookings: action.data,
        isLoading: false,
        preBookingSuccess: true,
    }
}

const getPreBookingFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const preBookingApprovalStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
        preBookingSuccess: false
    }
}

const preBookingApprovalSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        preBookingSuccess: true,
    }
}

const preBookingApprovalFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const cleanResponse = (state, action) => {
	return {
		...state,
		successResponse: null
	}
}


const bookingReducer = (state = initState, action) => {
    switch (action.type) {

        case actions.GET_BOOKINGS_START:
            return getBookingsStart(state, action);
        case actions.GET_BOOKINGS_SUCCESS:
            return getBookingsSuccess(state, action);
        case actions.GET_BOOKINGS_FAIL:
            return getBookingsFail(state, action);

        case actions.GET_BOOKING_START:
            return getBookingStart(state, action);
        case actions.GET_BOOKING_SUCCESS:
            return getBookingSuccess(state, action);
        case actions.GET_BOOKING_FAIL:
            return getBookingFail(state, action);

        case actions.DELETE_BOOKING_START:
            return deleteBookingStart(state, action);
        case actions.DELETE_BOOKING_SUCCESS:
            return deleteBookingSuccess(state, action);
        case actions.DELETE_BOOKING_FAIL:
            return deleteBookingFail(state, action);

        case actions.ADMIN_TO_USER_START:
            return adminToUserStart(state, action);
        case actions.ADMIN_TO_USER_SUCCESS:
            return adminToUserSuccess(state, action);
        case actions.ADMIN_TO_USER_FAIL:
            return adminToUserFail(state, action);
		case 'RESPONSE_CLEAN':
			return cleanResponse(state, action);

        case actions.GET_PRE_BOOKING_START:
            return getPreBookingStart(state, action);
        case actions.GET_PRE_BOOKING_SUCCESS:
            return getPreBookingSuccess(state, action);
        case actions.GET_PRE_BOOKING_FAIL:
            return getPreBookingFail(state, action);

        case actions.PRE_BOOKING_APPROVAL_START:
            return preBookingApprovalStart(state, action);
        case actions.PRE_BOOKING_APPROVAL_SUCCESS:
            return preBookingApprovalSuccess(state, action);
        case actions.PRE_BOOKING_APPROVAL_FAIL:
            return preBookingApprovalFail(state, action);

        default:
            return state;
    }
}

export default bookingReducer;
