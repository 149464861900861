import * as actions from './actions';

const initState = {
    data: {},
    error: null,
    isLoading: false
};

const getDashboardStart = (state, action) => {
    return {
        ...state,
        error: null,
        isLoading: true,
    }
}

const getDashboardSuccess = (state, action) => {
    return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
    }
}

const getDashboardFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        isLoading: false
    }
}

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_DASHBOARD_START:
            return getDashboardStart(state, action);
        case actions.GET_DASHBOARD_SUCCESS:
            return getDashboardSuccess(state, action);
        case actions.GET_DASHBOARD_FAIL:
            return getDashboardFail(state, action);
       
        default:
            return state;
    }
}

export default dashboardReducer;